.noti-modal {
    position: fixed;
    top: 48px;
    right: 84px;
    width: 450px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 8px;
}

.noti-modal-content {
    padding: 20px;
}

.noti-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cccccc3b;
    padding-bottom: 10px;
}

.noti-modal-header h2 {
    margin: 0;
    font-size: 16px;
    color: #282828;
}

.noti-clear-all-btn {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0px;
    border-radius: 4px;
    cursor: pointer;
    color: #dd0f00;
}

.noti-modal-body {
    margin-top: 15px;
    max-height: 300px;
    overflow-y: auto;
}

.notification-item {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* gap: 1rem; */
    padding: 7px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.notification-item p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: #282828;
}

.notification-item span {
    font-size: 13px;
    color: #282828ad;
    font-weight: 500;
    margin-top: -5px;
}

.noti-delete-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}

.delete-btn:hover, .clear-all-btn:hover {
    background-color: #ff1a1a;
}