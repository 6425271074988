.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  background-color: #ffffff !important;
  height: 64px;
}

.css-8u39c-MuiTypography-root {
  margin-top: 4px !important;
}

.admin-logo {
  overflow: hidden;
}

.search_icon {
  position: absolute;
  display: flex !important;
  justify-content: flex-end;
  left: 79%;
  top: 15px;
  color: #979494;
}

.search_div_header {
  position: relative;
}

.search_header_input {
  width: 560px !important;
  border-radius: 100px !important;
  border: 0.5px solid #d9d9d9 !important;
  background: #fafafa80 !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-inline-start: 33px !important;
}

.search_header_input::placeholder {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: left;
  color: #818181;
}

.search_icon_input {
  position: absolute;
  top: 4px;
  left: 12px;
}

.search_filter_icon {
  position: absolute;
  top: 0px;
  right: 0px;
}

.search_filter_icon svg {
  height: 35px;
  width: 35px;
}

.mail_svg_div {
  position: relative;
}

.mail_svg_div::after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 0;
  right: 0;
  left: 32px;
  border-right: 1px solid #d9d9d9;
  height: 18px;
}

.btnCopy {
  border: none;
  border-radius: 10px;
  padding: 3px 20px;
  font-size: 14px;
  text-decoration: underline;
}

.search_input {
  border-radius: 4px;
  height: 37px;
}

.css-h4y409-MuiList-root {
  background: black;
}

/* .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
    background-color: #ffff !important;
} */
.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}

.bg_dashboard {
  color: #ffffff;
}

.css-9mgopn-MuiDivider-root {
  background-color: #ffffff;
}

.active {
  /* background: #ffd8db; */
  color: #f99f21;
}

.icon_bg {
  /* background: rgba(133, 18, 152, 0.80); */
  color: #dc3545 !important;
}

.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  width: 1px;
}

.link_tag {
  text-decoration: none;
  color: #ffffff;
}

.link_tag_sidebar {
  text-decoration: none;
  color: #ffffff;
}

.css-10hburv-MuiTypography-root {
  font-family: Outfit !important;
  font-size: 0.9rem !important;
}

.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background: linear-gradient(90deg, #000 50%, #0000 0) right/200% 100%;
  animation: l21 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* Change 'relative' to 'absolute' */
  top: 50%;
  left: 50%;
  /* Add this line */
  transform: translate(-50%, -50%);
  /* Center horizontally and vertically */
}

.loader::before {
  content: "Loading...";
  color: #0000;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg, #fff 50%, #000 0);
  -webkit-background-clip: text;
  background-clip: text;
}

.close_menu_btn {
  background-color: transparent;
  border: none;
  outline: none;
  display: none;
}

.close_menu_btn svg {
  font-size: 24px;
}

@keyframes l21 {
  100% {
    background-position: left;
  }
}

.header_heading {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.admin_title_header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.time_header_para {
  color: #626262;
  font-size: 13px;
  font-family: Outfit;
  font-weight: 400;
  margin-bottom: 0px;
}

.drawer-btn-box .drawer-btn {
  background-color: #000000 !important;
  margin-left: -2px !important;
}

.drawer-btn-box .MuiButtonBase-root {
  background-color: #000000 !important;
  margin-left: -2px !important;
}

.admin-logo-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.admin-logo {
  width: 100px;
}


@media (max-width: 600px) {
  .close_menu_btn {
    display: block;
  }

  .admin-logo-sec {
    display: none !important;
  }

  .css-8u39c-MuiTypography-root {
    font-size: 1rem !important;
  }

  .css-6iz4mj-MuiButtonBase-root-MuiIconButton-root {
    background-color: #000000 !important;
    margin-left: -2px !important;
  }
}


@media (min-width: 1500px) {
  .search_header_input {
    width: 1100px !important;
  }
}